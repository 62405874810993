import { alpha, RadioGroup } from '@material-ui/core';
import styled from 'styled-components';

import { Theme, containerSmallWidth, palette } from '../../theme';
import { ButtonLink } from '../ButtonLink';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const DayPassOverlayContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: ${containerSmallWidth};
    }
`;

export const StyledModalContentHeading = styled(Heading)`
    padding-top: 3rem;
    padding-left: 3rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-bottom: 1.5rem;
        padding-left: 0;
        text-align: center;
    }
`;

export const StyledRadioGroup = styled(RadioGroup)`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 46.8% 46.8%;
    padding: 0 3rem 2.2rem 3rem;
    > * {
        &:first-child {
            grid-column: span 2;
        }
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        grid-template-columns: 27.4rem 27.4rem;
    }
`;

const checkedStyle = (theme: Theme) => `
    color: ${palette.common.white};
    background-color: ${palette.secondary.main};
    border: 2px solid ${alpha(palette.secondary.dark, 0.5)};
`;

const disabledStyle = (theme: Theme) => `
    color: ${palette.common.blue};
    background-color: ${palette.grey[100]};
    opacity: 0.25;
`;

export const StyledDayOptionContainer = styled.div<{
    $isInputChecked: boolean;
    $isInputDisabled: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8.9rem;
    font-size: 1.9rem;
    font-family: thegymgroupsans-Headline;
    border: 2px solid ${({ theme }) => alpha(palette.primary.main, 0.2)};
    border-radius: 0.8rem;
    ${({ theme, $isInputChecked }) => $isInputChecked && checkedStyle(theme)}
    ${({ theme, $isInputDisabled }) => $isInputDisabled && disabledStyle(theme)}

    cursor: ${({ $isInputDisabled }) =>
        $isInputDisabled ? 'default' : 'pointer'};

    &:focus-within {
        outline: 0.2rem solid ${palette.primary.dark};
    }
`;

export const StyledRadioInput = styled.input`
    appearance: none;
`;

export const StyledLabel = styled.label<{ $isInputChecked: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    color: ${({ theme, $isInputChecked }) =>
        $isInputChecked ? palette.common.blue : palette.primary.main};
    text-transform: lowercase;
`;

export const StyledDateText = styled(Paragraph)`
    padding-top: 0.3rem;
    color: ${palette.common.blue};
    font-size: 1.4rem;
`;

export const StyledButtonLink = styled(ButtonLink)`
    margin-bottom: 3.2rem;
    font-size: 1.87rem;
    font-family: thegymgroupsans-Headline;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 3.6rem;
    }
`;

export const AlertMessageOverlayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 42.8rem;
        height: 50rem;
    }
`;

export const StyledAlertContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 19.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 12rem;
    }
`;

export const StyledAlertHeading = styled(Heading)`
    margin-top: 3.1rem;
    font-size: 1.9rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.1rem;
    }
`;
