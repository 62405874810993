import { useMediaQuery } from '@material-ui/core';

import { theme, palette } from '../../theme';
import { Icon } from '../Icon';
import { BestDealChip } from './BestDealChip';
import {
    StyledHeadingWrapper,
    StyledCtaAligner,
    StyledCtaContainer,
    StyledDescription,
    StyledProductHeading,
    StyledHorizontalRule,
    StyledJoiningFeeContainer,
    StyledDescriptionWrapper,
    StyledPanelV2,
} from './MembershipPanel.styled';
import { MembershipPanelProperties } from './MembershipPanel.types';
import { Price } from './Price';
import { WasPrice } from './WasPrice';
import { YourPlanChip } from './YourPlanChip';
import {
    decimalToPoundsAndPence,
    getMembershipKickerDiscountValues,
    getMembershipWasPrice,
} from '@tgg/util';

/**
 * Use the `MembershipPanel` component alongside `Benefits PinoTable` to display different membership options.
 */
export const MembershipPanel = ({
    iconName,
    viewBox,
    membershipTitle,
    price,
    joiningFee,
    description,
    children,
    ctas,
    variant,
    align = 'left',
    ctaAlign = align,
    fullWidth = false,
    includeVerticalRule = false,
    showBorder,
    bestDealChip = false,
    yourPlanChip = false,
    onClick,
    selected,
    compact,
}: MembershipPanelProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const wasPrice = getMembershipWasPrice({
        price: price?.value,
        originalPrice: price?.originalValue,
        isKickerPriceInForce: price?.isKickerPriceInForce,
        kickerPriceMonthlyFee: price?.kickerPriceMonthlyFee,
    });

    const kickerDiscountValues = getMembershipKickerDiscountValues({
        price: price?.value,
        isKickerPriceInForce: price?.isKickerPriceInForce,
        kickerPriceMonthlyFee: price?.kickerPriceMonthlyFee,
        kickerPriceDuration: price?.kickerPriceDuration,
    });

    const kickerDiscountMessage = kickerDiscountValues && (
        <>
            your first {kickerDiscountValues.durationString}.
            {isDesktop ? ' ' : <br />}Then{' '}
            <strong>£{kickerDiscountValues.fullPrice}</strong> per month
        </>
    );

    return (
        <StyledPanelV2
            $includeVerticalRule={includeVerticalRule}
            $variant={variant}
            $selected={selected}
            $showBorder={showBorder}
            onClick={onClick}
            data-testid="panel"
        >
            <div>
                <StyledDescriptionWrapper
                    $alignment={align}
                    $variant={variant}
                    $selected={selected}
                    $showBorder={showBorder}
                    $compact={compact}
                    data-testid="membership-wrapper"
                >
                    {bestDealChip && (
                        <BestDealChip
                            placeholder={bestDealChip === 'placeholder'}
                        />
                    )}

                    {yourPlanChip && (
                        <YourPlanChip
                            placeholder={yourPlanChip === 'placeholder'}
                        />
                    )}

                    <StyledHeadingWrapper
                        $alignment={align}
                        data-testid="membership-heading-wrapper"
                    >
                        <Icon
                            name={iconName}
                            color={
                                selected &&
                                (variant === 'green' ||
                                    variant === 'alternative-green')
                                    ? palette.common.blue
                                    : palette.primary.main
                            }
                            size={26}
                            viewBox={viewBox}
                        />
                        <StyledProductHeading
                            variant="h2"
                            gutterBottom={false}
                            color={
                                selected &&
                                (variant === 'green' ||
                                    variant === 'alternative-green')
                                    ? palette.common.blue
                                    : palette.primary.main
                            }
                        >
                            {membershipTitle}
                        </StyledProductHeading>
                    </StyledHeadingWrapper>
                    <div>
                        {wasPrice && <WasPrice wasPrice={wasPrice} />}
                        {price && (
                            <Price
                                color={palette.common.blue}
                                price={price.value}
                                frequency={price.frequency}
                                discountMessage={kickerDiscountMessage}
                            />
                        )}
                    </div>
                    {joiningFee !== undefined && (
                        <StyledJoiningFeeContainer
                            variant="body1"
                            gutterBottom={false}
                        >
                            {Number(joiningFee) === 0
                                ? '+ No joining fee'
                                : `+ ${decimalToPoundsAndPence(
                                      joiningFee,
                                      false,
                                  )} joining fee`}
                        </StyledJoiningFeeContainer>
                    )}
                    {description && (
                        <StyledDescription
                            variant="body1"
                            gutterBottom={false}
                            $alignment={align}
                        >
                            {description}
                        </StyledDescription>
                    )}
                </StyledDescriptionWrapper>
                <StyledHorizontalRule $variant={variant} $selected={selected} />
                {children}
            </div>
            {ctas && (
                <StyledCtaAligner
                    $alignment={ctaAlign}
                    $fullWidth={fullWidth}
                    data-testid="membership-cta-aligner"
                >
                    <StyledCtaContainer
                        $alignment={ctaAlign}
                        data-testid="membership-cta-container"
                    >
                        {ctas}
                    </StyledCtaContainer>
                </StyledCtaAligner>
            )}
        </StyledPanelV2>
    );
};

export default MembershipPanel;
