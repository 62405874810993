import styled from 'styled-components';

import { Theme, palette } from '../../theme';
import { Icon } from '../Icon';
import { ExpandedProperties } from './Expander.types';

export const StyledTitleContainer = styled.button`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 0 0 1.5rem;
    color: ${palette.common.blue};
    background: none;
    border: none;
    cursor: pointer;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 0 0 3rem;
    }
`;

export const StyledTitle = styled.p`
    display: inline-block;
    margin: 0;
    padding: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
    font-family: thegymgroupsans-bold;
    text-decoration: underline;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 1.2rem;
        font-size: 1.6rem;
    }
`;

export const StyledIconContainer = styled.div<ExpandedProperties>`
    position: relative;
    display: inline-block;
    line-height: 0;
    ${({ $expanded }) => ($expanded ? 'transform: rotate(45deg);' : '')};
    transition: all 0.2s;
`;

export const StyledIcon = styled(Icon)`
    cursor: pointer;
`;

export const StyledContent = styled.div<ExpandedProperties>`
    max-height: ${({ $expanded }) => ($expanded ? '100vh' : '0')};
    padding: 0 4rem 0 4rem;
    overflow: hidden;
    font-weight: normal;
    font-size: 1.6rem;
    transition: all 0.7s;
    transition-timing-function: ${({ $expanded }) =>
        $expanded ? 'ease-out' : 'cubic-bezier(0,1,0,1)'};
`;
