import { useMediaQuery } from '@material-ui/core';

import { palette, theme } from '../../theme';
import { ButtonBase } from '../Button';
import { Icon } from '../Icon';
import { Overlay } from '../Overlay';
import { Paragraph } from '../Paragraph';
import { StickyNavigationButton } from '../StickyNavigationButton';
import {
    AlertMessageOverlayContainer,
    DayPassOverlayContentContainer,
    StyledAlertContentContainer,
    StyledAlertHeading,
    StyledButtonLink,
    StyledDateText,
    StyledDayOptionContainer,
    StyledLabel,
    StyledModalContentHeading,
    StyledRadioGroup,
    StyledRadioInput,
} from './DayPassSelection.styled';
import { DayPassSelectionProperties } from './DayPassSelection.types';
import { DayPassAvailability } from '@tgg/common-types';
import { getOrdinalDateFromDate, getWeekDayFromDate } from '@tgg/util';

export const DayPassSelection = ({
    onSelect,
    isOpen,
    setIsOpen,
    selectedProduct,
    selectedDayPassStartDate,
    setSelectedDayPassStartDate,
    checkedIndex,
    setCheckedIndex,
    isShowMoreDaysToggled,
    setIsShowMoreDaysToggled,
    todaysDate,
}: DayPassSelectionProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    return (
        <>
            {isOpen.isDatesOverlayOpen && (
                <Overlay
                    contentContainerHasPadding={false}
                    contentContainerisFullHeight
                    title="START DATE"
                    titleVariant="h1"
                    open={isOpen.isDatesOverlayOpen}
                    handleClose={() => {
                        setIsOpen({ ...isOpen, isDatesOverlayOpen: false });
                        setIsShowMoreDaysToggled(false);
                    }}
                >
                    <DayPassOverlayContentContainer>
                        <div>
                            <StyledModalContentHeading variant="h2">
                                Available Start Date
                            </StyledModalContentHeading>
                            <StyledRadioGroup
                                aria-label="daypass start dates"
                                defaultValue=""
                                name="daypass-start-dates-radio-buttons-group"
                            >
                                {selectedProduct?.dayPassDays
                                    ?.slice(0, isShowMoreDaysToggled ? 14 : 7)
                                    .map((daypass, index) => {
                                        const startDateWithoutTime =
                                            daypass.startDate.split('T')[0];
                                        let weekDay =
                                            getWeekDayFromDate(
                                                startDateWithoutTime,
                                            );
                                        if (
                                            index === 0 &&
                                            todaysDate ===
                                                new Date(
                                                    startDateWithoutTime,
                                                ).toDateString()
                                        ) {
                                            weekDay = 'TODAY';
                                        }

                                        const isInputChecked =
                                            checkedIndex === index;
                                        return (
                                            <StyledDayOptionContainer
                                                key={startDateWithoutTime}
                                                $isInputChecked={isInputChecked}
                                                $isInputDisabled={
                                                    daypass.isDisabled
                                                }
                                                onClick={() => {
                                                    setCheckedIndex(index);
                                                    setSelectedDayPassStartDate(
                                                        daypass,
                                                    );
                                                }}
                                            >
                                                <StyledLabel
                                                    htmlFor={`radio-daypass-day-option-${index}`}
                                                    $isInputChecked={
                                                        isInputChecked
                                                    }
                                                >
                                                    {weekDay}
                                                </StyledLabel>
                                                <StyledRadioInput
                                                    type="radio"
                                                    id={`radio-daypass-day-option-${index}`}
                                                    name={`radio-daypass-day-option-${index}`}
                                                    value={startDateWithoutTime}
                                                    checked={isInputChecked}
                                                    onChange={() => {
                                                        setCheckedIndex(index);
                                                        setSelectedDayPassStartDate(
                                                            daypass,
                                                        );
                                                    }}
                                                    disabled={
                                                        daypass.isDisabled
                                                    }
                                                />
                                                <StyledDateText
                                                    variant="subtitle1"
                                                    gutterBottom={false}
                                                >
                                                    {getOrdinalDateFromDate(
                                                        startDateWithoutTime,
                                                    )}
                                                </StyledDateText>
                                            </StyledDayOptionContainer>
                                        );
                                    })}
                            </StyledRadioGroup>
                        </div>

                        <StickyNavigationButton
                            buttonText="CONFIRM DATE"
                            isButtonFullWidth={!isDesktop}
                            onClick={() => {
                                onSelect(
                                    selectedProduct,
                                    selectedDayPassStartDate as DayPassAvailability,
                                );
                            }}
                            backgroundColor={palette.common.white}
                        >
                            <StyledButtonLink
                                onClick={() =>
                                    setIsShowMoreDaysToggled(
                                        !isShowMoreDaysToggled,
                                    )
                                }
                                color={palette.common.blue}
                            >
                                {isShowMoreDaysToggled
                                    ? 'Show less days'
                                    : 'Show more days'}
                            </StyledButtonLink>
                        </StickyNavigationButton>
                    </DayPassOverlayContentContainer>
                </Overlay>
            )}
            {isOpen.isNoDatesOverlayOpen && (
                <Overlay
                    title="START DATE"
                    open={isOpen.isNoDatesOverlayOpen}
                    handleClose={() =>
                        setIsOpen({ ...isOpen, isNoDatesOverlayOpen: false })
                    }
                    contentContainerisFullHeight
                >
                    <AlertMessageOverlayContainer>
                        <StyledAlertContentContainer>
                            <Icon
                                name="alert"
                                color={palette.primary.main}
                                size={100}
                            />
                            <StyledAlertHeading variant="h2">
                                OOPS!
                            </StyledAlertHeading>
                            <Paragraph gutterBottom={false}>
                                Seems like there are no dates available.
                            </Paragraph>
                        </StyledAlertContentContainer>
                        <ButtonBase
                            text="CLOSE"
                            fullWidth={!isDesktop}
                            onClick={() =>
                                setIsOpen({
                                    ...isOpen,
                                    isNoDatesOverlayOpen: false,
                                })
                            }
                        />
                    </AlertMessageOverlayContainer>
                </Overlay>
            )}
        </>
    );
};
