import styled from 'styled-components';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

export const StyledDialogTitle = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 3rem 2rem 3rem;
    color: ${palette.common.white};
    background-color: ${palette.primary.main};
`;

export const StyledDialogContent = styled.div`
    padding: 3rem;
    overflow-y: auto;
    color: ${palette.common.blue};
`;

export const StyledHeading = styled(Heading)<{
    $maxWidth?: number;
}>`
    flex: 1 1 auto;
    margin: 0;
    padding-right: 2rem;
    color: ${palette.common.white};
    font-size: 2.8rem;
    line-height: 3rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 0 0 0 3.5rem;
        padding-right: 0;
        font-size: 3.6rem;
        line-height: 3.8rem;
    }
`;

export const StyledCloseButton = styled.button`
    display: flex;
    height: fit-content;
    margin: 0;
    padding: 0.7rem 0 0 0;
    background-color: inherit;
    border: none;
    cursor: pointer;
    svg {
        margin: auto;
    }
`;

export const StyledBenefitsItem = styled.div`
    display: flex;
    flex-basis: 50%;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(375)} {
        gap: 1.5rem;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-basis: auto;
        flex-direction: column;
        gap: 0;
        align-items: center;
        width: ${({ theme }) => theme.spacing(95)};
        margin-bottom: 0;
        text-align: center;
    }
`;

export const StyledHorizontalRule = styled.hr`
    border: ${({ theme }) => theme.spacing(1)} solid ${palette.grey[100]};
    border-radius: ${({ theme }) => theme.spacing(1)};
`;

export const StyledOverlayHeading = styled(Heading)`
    font-size: 1.9rem;
    line-height: 2.2rem;

    && {
        margin-bottom: 2rem;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledSectionHeading = styled(StyledOverlayHeading)`
    color: ${palette.pino.ultimate};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: ${({ theme }) => theme.spacing(350)};
        margin: 0 auto;
    }
`;

export const StyledBoldText = styled.b`
    font-family: thegymgroupsans-Headline, sans-serif;
`;

export const StyledParagraph = styled(Paragraph)`
    && {
        margin-bottom: 0;
    }
`;

export const StyledSection = styled.section`
    margin: 3rem 0 2rem 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 2rem 0;
    }
`;

export const OverlayContentContainer = styled.div`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-inline: ${({ theme }) => theme.spacing(30)};
    }
`;

export const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 4rem;
    height: 4rem;
    padding: ${({ theme }) => theme.spacing(8)};
    border: 0.25rem solid ${palette.grey[900]};
    border-radius: 100%;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 6rem;
        height: 6rem;
        padding: ${({ theme }) => theme.spacing(12)};
    }
`;

export const StyledBenefitsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing(12)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 0;
        padding-inline: ${({ theme }) => theme.spacing(30)};
    }
`;

export const StyledBenefitHeading = styled(Paragraph)`
    && {
        width: ${({ theme }) => theme.spacing(85)};
        margin-bottom: 0;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            width: auto;
            margin-bottom: 2rem;
            padding-top: 0.5rem;
        }
    }
`;

export const StyledListItem = styled.li`
    && {
        margin-bottom: 1rem;
        padding-left: 1rem;
    }

    &:last-child {
        margin-bottom: 2rem;
    }
`;

export const StyledIcon = styled(Icon)`
    cursor: pointer;
`;
