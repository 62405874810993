import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { Theme, palette } from '../../../theme';
import { StyledWasPriceProperties } from './WasPrice.types';

const subtleStyle = (theme: Theme) => `
    font-size: 1rem;
    line-height: 1.6rem;
    color: ${palette.common.blue};
    ${theme.breakpoints.up('desktop')}: {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
`;

export const StyledWasPrice = styled(Typography).attrs({
    variant: 'h3',
    component: 'span',
})<StyledWasPriceProperties>`
    margin-right: 0.8rem;
    text-decoration: line-through;
    ${({ $isSubtle, theme }: StyledWasPriceProperties & { theme: Theme }) =>
        $isSubtle && subtleStyle(theme)}
`;
