import { Divider } from '@material-ui/core';
import styled from 'styled-components';

import { containerSmallWidth, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledDescription = styled(Paragraph)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: ${containerSmallWidth};
        margin: 0 auto;
        text-align: center;
    }
`;

export const StyledPinoSectionHeader = styled.section`
    margin: 0;
    padding: ${({ theme }) => theme.spacing(30)};
`;

export const StyledOptionButton = styled.div`
    display: flex;
    justify-content: center;
    margin: ${({ theme }) => theme.spacing(30)}
        ${({ theme }) => theme.spacing(30)} ${({ theme }) => theme.spacing(40)}
        ${({ theme }) => theme.spacing(30)};
`;

interface StyledProductOptionWrapperProperties {
    visible: boolean;
}

export const StyledProductOptionWrapper = styled.section<StyledProductOptionWrapperProperties>`
    width: 100%;
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const StyledDivider = styled(Divider)`
    margin-top: 3rem;
    margin-right: 3rem;
    margin-left: 3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 4rem;
    }
`;
