import { Card } from '@material-ui/core';
import styled from 'styled-components';

import { Paragraph } from '../Paragraph';

export const StyledCard = styled(Card)`
    margin: 3rem 0.2rem 0;
    padding: 3.2rem;
    border: none;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 4rem;
    }
    border-radius: 1.6rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 3rem 0 0;
    }

    ${({ theme }) => theme.breakpoints.down(468)} {
        padding: 2rem;
    }

    ${({ theme }) => theme.breakpoints.down(405)} {
        padding: 1.5rem;
    }
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 3rem;
    }
`;

export const StyledFixedProductOptions = styled.div`
    display: flex;
    gap: 0.3rem;

    & > article {
        flex: 1;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        gap: 1.3rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    && {
        margin-top: 3rem;
        margin-bottom: 4rem;
        text-align: center;
    }
`;
