import { palette } from '../../../theme';
import { Icon } from '../../Icon';
import { StyledCard, StyledBoldText } from './BestValue.styled';

export interface BestValueProperties {
    squeeze: boolean;
}

export const BestValue = ({ squeeze }: BestValueProperties) => {
    return (
        <StyledCard $squeeze={squeeze}>
            <Icon name="wellbeing" color={palette.common.white} size={12} />
            <StyledBoldText>best value</StyledBoldText>
        </StyledCard>
    );
};
