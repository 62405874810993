import useMediaQuery from '@material-ui/core/useMediaQuery';

import { palette, theme } from '../../../theme';
import { Icon } from '../../Icon';
import {
    StyledYourPlanChip,
    StyledYourPlanChipPlaceholder,
} from './YourPlanChip.styled';
import { YourPlanChipProperties } from './YourPlanChip.types';

export const YourPlanChip = ({ placeholder }: YourPlanChipProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    return placeholder ? (
        <StyledYourPlanChipPlaceholder data-testid="your-plan-chip-placeholder" />
    ) : (
        <StyledYourPlanChip>
            <Icon
                name="tick"
                color={palette.common.blue}
                size={isDesktop ? 22 : 18}
            />
            <span>your plan</span>
        </StyledYourPlanChip>
    );
};

export default YourPlanChip;
