import styled from 'styled-components';

import { palette } from '../../../theme';

interface StyledCardProperties {
    $squeeze?: boolean;
}

export const StyledCard = styled.div<StyledCardProperties>`
    position: absolute;
    top: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.4rem 1rem;
    color: ${palette.common.white};
    font-size: 1rem;
    line-height: 1.2rem;
    background-color: ${palette.common.blue};
    border-radius: 1rem;
    transform: translateY(-50%);

    ${({ theme }) => theme.breakpoints.down(468)} {
        svg {
            flex: 1;
        }

        b {
            flex: 2;
            white-space: nowrap;
        }

        ${({ $squeeze }) => {
            if ($squeeze) {
                return `
                    left: 0;

                    b {
                        white-space: wrap;
                    }
                `;
            }

            return '';
        }}
    }
`;

export const StyledBoldText = styled.b`
    font-weight: 400;
    font-family: thegymgroupsans-Headline, sans-serif;
`;
