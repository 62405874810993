import styled from 'styled-components';

import {
    Theme,
    containerLargeWidth,
    containerSmallWidth,
    palette,
} from '../../theme';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';
import { Paragraph } from '../Paragraph';

export const StyledWrapper = styled.div`
    max-width: 100%;
    margin: 0 auto;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 100%;
        max-width: ${containerLargeWidth};
    }
`;

export const StyledHeaderSection = styled.div`
    margin: 0 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 0;
        text-align: center;
    }
`;

export const StyledHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
`;

export const StyledExpanderHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
    padding: 0 0 0 1.5rem;
    font-size: 1.8rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 0 0 3rem;
    }
`;

export const StyledDescription = styled(Paragraph)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: ${containerSmallWidth};
        margin: 0 auto;
    }
`;

export const StyledPanelsWithHeaderWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing(20)};
`;

export const StyledExpanderSection = styled.div`
    margin-top: 1rem;
`;

export const FullBenefitsWrapper = styled.div`
    padding-left: 0.8rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-left: 2.3rem;
    }
`;

export const StyledRadioContainer = styled.label<{
    $checked?: boolean;
    $variant: 'green' | 'blue';
}>`
    position: relative;
    width: 4.2rem;
    height: 4.2rem;
    margin: 2rem 0;
    background-color: ${palette.common.white};
    border: 1px solid ${palette.grey[900]};
    border-radius: 2.25rem;
    input {
        position: absolute;
        width: 4.2rem;
        height: 4.2rem;
        opacity: 0;

        + div {
            padding: 0.5rem;
        }
    }
    svg {
        margin: 0.1rem;
        ${({ $checked }) => $checked && `margin: 0.2rem;`}
    }
`;

export const StyledJoinButtonContainer = styled.div`
    width: 100%;
    max-width: 34.6rem;
    margin: 3rem auto;
`;

export const StyledFixedMembershipParagraph = styled(Paragraph)`
    padding: 1rem 0 0 3rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 1rem 20rem 0 20rem;
    }
`;

export const StyledFixedHeading = styled(Heading)`
    padding: 0 0 1rem 0;
    color: ${({ theme }) => theme.palette.common.blue};
    font-size: 1.4rem;
    text-align: center;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 4rem 0 1.5rem 0;
        font-size: 1.6rem;
    }
`;

export const StyledPanelsFixedHeader = styled(Heading)`
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.palette.common.blue};
    font-size: 1.4rem;
    text-align: center;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin: 3rem 0 2rem 0;
`;
