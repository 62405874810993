import { Card } from '@material-ui/core';
import styled from 'styled-components';

import { palette } from '../../theme';
import { Heading } from '../Heading';

export const StyledCard = styled(Card)`
    margin: 3rem 0.2rem 0;
    padding: 3.2rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 4rem;
    }
    border-radius: 1.6rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 3rem 0 0;
    }

    ${({ theme }) => theme.breakpoints.down(468)} {
        padding: 2rem;
    }

    ${({ theme }) => theme.breakpoints.down(405)} {
        padding: 1.5rem;
    }
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 3rem;
    }
`;

export const StyledHeader = styled.header`
    display: flex;
    align-content: center;
    justify-content: space-between;

    margin-bottom: 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 4rem;
    }
`;

export const StyledFixedProductOptions = styled.div`
    display: flex;
    gap: 0.3rem;

    & > article {
        flex: 1;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        gap: 1.3rem;
    }
`;

export const StyledSectionHeading = styled(Heading)`
    margin: 3.2rem 3rem 0 3rem;
    color: ${palette.common.blue};
    font-size: 1.4rem;
    font-family: thegymgroupsans-Headline, sans-serif;
    line-height: 1.4rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 1.6rem;
        text-align: center;
    }
`;
