import { palette } from '../../../theme';
import { Icon } from '../../Icon';
import {
    StyledBestDealChip,
    StyledBestDealChipPlaceholder,
} from './BestDealChip.styled';
import { BestDealChipProperties } from './BestDealChip.types';

export const BestDealChip = ({ placeholder }: BestDealChipProperties) =>
    placeholder ? (
        <StyledBestDealChipPlaceholder data-testid="best-deal-chip-placeholder" />
    ) : (
        <StyledBestDealChip>
            <Icon name="wellbeing" color={palette.common.white} size={22} />
            <span>best deal</span>
        </StyledBestDealChip>
    );

export default BestDealChip;
