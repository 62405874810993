/* istanbul ignore file */
import { alpha } from '@material-ui/core/styles';
import styled from 'styled-components';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';
import { Paragraph } from '../Paragraph';
import {
    StyledComponentProperties,
    StyledPanelProperties,
    Variant,
} from './MembershipPanel.types';

export const StyledPanelV2 = styled.div<StyledPanelProperties>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 2rem 0.1rem 0;
    border: 0.3rem solid transparent;
    border-radius: 1.6rem;

    ${({ $variant }) =>
        `hr {
        background-color: ${alpha(styleMap[$variant], 0.22)};
    }`};

    ${({ $includeVerticalRule }) =>
        $includeVerticalRule && `border-left: 1px solid ${palette.grey[200]}`};

    ${({ $variant, $selected, $showBorder }) =>
        $selected &&
        $showBorder !== false &&
        `border-color:${styleMap[$variant]};
            hr {
                background-color: ${styleMap[$variant]};
            }
            label.radio-container {
                border-radius: 21px;
                box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
                border:0;
                background-color: ${palette.secondary.main};
                g {
                    fill: ${palette.common.blue};
                }
            }`};

    ${({ $variant, $showBorder, theme, $selected }) => {
        if (
            $showBorder !== false &&
            $variant === 'alternative-green' &&
            $selected === false
        ) {
            return `border-color: ${theme.palette.grey[200]};`;
        }
        return null;
    }}

    ${({ $variant, $showBorder }) =>
        $showBorder !== false &&
        `:hover {
            cursor: pointer;
            border-color: ${styleMap[$variant]};
            hr {
                background-color: ${styleMap[$variant]};
            }
        }
    `};

    ${({ $variant }) =>
        ($variant === 'green' || $variant === 'alternative-green') &&
        `:hover {
            border-color: ${alpha(styleMap[$variant], 0.5)};
            ${StyledDescriptionWrapper} {
                background-color: ${alpha(styleMap[$variant], 0.5)};
            }
            hr {
                background-color: ${alpha(styleMap[$variant], 0.5)};
                width: 100%;
                margin: 0;
            }

            ${StyledHeadingWrapper} {
                svg g {
                    fill: ${palette.common.blue};
                }
            }
            ${StyledProductHeading} {
                color: ${palette.common.blue};
            }
        }
    `};

    ${({ $variant, $selected }) =>
        ($variant === 'green' || $variant === 'alternative-green') &&
        $selected &&
        `:hover {
            border-color: ${styleMap[$variant]};
            ${StyledDescriptionWrapper} {
                background-color: ${styleMap[$variant]};
            }
            hr {
                background-color: ${styleMap[$variant]};
            }
        }
    `};
`;

export const StyledHeadingWrapper = styled.div<StyledComponentProperties>`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: ${({ $alignment }) =>
        $alignment === 'center' ? undefined : '-0.6rem'};
`;

export const StyledDescriptionWrapper = styled.div<StyledComponentProperties>`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    ${({ $compact }) => {
        if ($compact) {
            return `padding: 2rem 2.8rem 1rem;`;
        }
        return `padding: 2rem 1.5rem 1rem;`;
    }}
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        align-items: ${({ $alignment }) => $alignment};
        padding: 2rem 3rem 2.4rem;

        ${({ $alignment }) =>
            $alignment === 'center' &&
            `
            align-items: baseline;
            padding-left: 20rem;
        `};
    }

    ${({ $variant, $selected }) =>
        $selected &&
        ($variant === 'green' || $variant === 'alternative-green') &&
        `
        background-color: ${palette.secondary.main};
    `}

    ${({ $variant, $showBorder }) =>
        $showBorder !== false &&
        ($variant === 'green' || $variant === 'alternative-green') &&
        `:hover {
            background-color: ${palette.secondary.main};
        }
    `};
`;

export const StyledHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
`;

export const StyledProductHeading = styled(Heading)`
    margin-right: 0.5rem;
    margin-left: 0.5rem;
`;

export const StyledJoiningFeeContainer = styled(Paragraph)`
    margin-top: 0.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 1rem;
    }
`;

export const StyledDescription = styled(Paragraph)<StyledComponentProperties>`
    margin-left: ${({ $alignment }) =>
        $alignment === 'center' ? undefined : '-0.6rem'};
`;

export const StyledHorizontalRule = styled(HorizontalRule)<{
    $variant: Variant;
    $selected?: boolean;
}>`
    height: 0.3rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;

    ${({ $variant, $selected }) =>
        $selected &&
        ($variant === 'green' || $variant === 'alternative-green') &&
        `
        width:100%;
        margin: 0;
    `}

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-right: 3rem;
        margin-left: 3rem;

        ${({ $variant, $selected }) =>
            $selected &&
            ($variant === 'green' || $variant === 'alternative-green') &&
            `
        width:100%;
        margin: 0;
    `}
    }
`;

export const StyledCtaAligner = styled.div<StyledComponentProperties>`
    display: flex;
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : undefined)};
    margin: ${({ $alignment }) =>
        $alignment === 'center' ? '0 auto' : undefined};
`;

export const StyledCtaContainer = styled.div<StyledComponentProperties>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-right: 1.5rem;
    margin-left: 1.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-right: ${({ $alignment }) =>
            $alignment === 'center' ? undefined : '3rem'};
        margin-left: ${({ $alignment }) =>
            $alignment === 'center' ? undefined : '3rem'};
    }

    button,
    a {
        margin-top: 2rem;
    }
`;

export const styleMap = {
    'alternative-green': palette.secondary.main,
    green: palette.secondary.main,
    blue: palette.primary.main,
};
