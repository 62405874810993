import { useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { theme } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { PinoProductOption } from '../PinoProductOption';
import { ProductOptionIcons } from '../PinoProductOptions/ProductOptionIcons';
import {
    generateCTAHref,
    getPinoOrientation,
    getSelectedProduct,
} from './PinoStudentProductOptions.helpers';
import {
    StyledButtonContainer,
    StyledCard,
    StyledFixedProductOptions,
    StyledHeader,
    StyledSectionHeading,
} from './PinoStudentProductOptions.styled';
import { PinoStudentProductOptionsProperties } from './PinoStudentProductOptions.types';
import { PinoProduct } from '@tgg/common-types';
import { sendAnalyticsDataEvent, DataEventNames } from '@tgg/services';
import { monthlyProductTitleMap } from '@tgg/util';

export const PinoStudentProductOptions = ({
    gymName,
    ctaHref,
    products,
    onSelect,
    productsAvailableAddons,
}: PinoStudentProductOptionsProperties) => {
    const selectedProduct = getSelectedProduct(products);

    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const router = useRouter();
    const [optionSelected, setOptionSelected] =
        useState<PinoProduct>(selectedProduct);

    const selectedProductTitle =
        monthlyProductTitleMap[selectedProduct.membershipType];

    const { contentColor } = getPinoColor(selectedProductTitle);

    const handleSelectedProductOption = (product: PinoProduct) => {
        sendAnalyticsDataEvent({
            eventName: DataEventNames.TERM_SELECT,
            eventData: product,
            eventGymName: gymName,
        });
        setOptionSelected(product);
    };

    const handleJoinNow = async () => {
        onSelect(optionSelected);

        sendAnalyticsDataEvent({
            eventName: DataEventNames.JOIN_NOW,
            eventData: optionSelected,
            eventGymName: gymName,
        });

        const generatedCTAHref = generateCTAHref(
            productsAvailableAddons,
            optionSelected,
            ctaHref,
        );

        await router.push(generatedCTAHref);
    };

    const orientation = getPinoOrientation(products.length, isDesktop);

    return (
        <>
            <StyledSectionHeading variant="h2" gutterBottom={false}>
                2. Select your student term
            </StyledSectionHeading>
            <StyledCard variant="outlined">
                <StyledHeader>
                    <Heading
                        variant="h3"
                        color={contentColor}
                        gutterBottom={false}
                    >
                        student
                    </Heading>
                    <ProductOptionIcons
                        productTitle={selectedProductTitle}
                        color={contentColor}
                    />
                </StyledHeader>
                {products.length > 0 && (
                    <StyledFixedProductOptions>
                        {products
                            .sort((a, b) => b.price.value - a.price.value)
                            .map((product, index) => (
                                <PinoProductOption
                                    key={product.id}
                                    product={product}
                                    pinoProductSelected={
                                        product.id === optionSelected.id
                                    }
                                    title={selectedProductTitle}
                                    numberOfElements={products.length}
                                    orientation={orientation}
                                    onSelectProductOption={
                                        handleSelectedProductOption
                                    }
                                />
                            ))}
                    </StyledFixedProductOptions>
                )}

                <StyledButtonContainer>
                    <ButtonBase
                        type="button"
                        aria-label={`Join Now with ${optionSelected.description} membership`}
                        text="Join Now"
                        onClick={handleJoinNow}
                    />
                </StyledButtonContainer>
            </StyledCard>
        </>
    );
};
