import styled from 'styled-components';

import { palette } from '../../../theme';

export const StyledBestDealChip = styled.div`
    display: flex;
    align-items: center;
    width: 11rem;
    height: 3.1rem;
    margin-bottom: 1.5rem;
    margin-left: -0.6rem;
    padding: 0 1rem;
    color: ${palette.common.white};
    font-family: thegymgroupsans-Headline;
    background: ${palette.common.blue};
    border-radius: 2rem;

    span {
        margin-left: 0.5rem;
    }

    svg {
        align-self: center;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 11.7rem;
        height: 3.2rem;
        border-radius: 2rem;
    }
`;

export const StyledBestDealChipPlaceholder = styled.div`
    width: 11rem;
    height: 3.1rem;
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 12.4rem;
        height: 3.4rem;
    }
`;
